import React, { useState, useEffect } from 'react'
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { Circle, DeleteOutline } from '@mui/icons-material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import SendIcon from '@mui/icons-material/Send'
import InformationIcon from '../../../shared/components/InformationIcon'
import UserManagementTableUI from './UserManagementTableUI'
import NetworkUtil from '../../../services/networking/NetworkingUtil'
import axios from '../../../services/networking/axios'
import { useLoader } from '../../../hooks'
import OverlayLoader from '../../../shared/components/OverlayLoader'
import { DatePicker } from '@mui/x-date-pickers'
import { DatePickerTextField } from '../../DateRangePickerChart/styles'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import DialogActions from '@mui/material/DialogActions'
import ErrorIcon from '@mui/icons-material/Error'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import moment from 'moment'
import DiscardBucketPopUp from '../../../shared/components/PopUp/DiscardBucketPopUp'
import _ from 'lodash'
import getNewUiToken from "../../../shared/utils/getNewUiToken"
import { convertListKeysToCamelCase } from "../../../shared/utils/helperFunctions"
import { getBaseURL,getRequestOptions } from '../../../shared/utils/migrationHelper'
const BASE_URL = getBaseURL()

const formatDate = 'DD-MMM-YYYY'

const UserManagementTable = ({ userList, setUserList, handleAddUser }) => {
  const [searchValue, setSearchValue] = useState('')
  const [showLoader, setShowLoader] = useLoader()
  const [clientTypeFilter, setClientTypeFilter] = useState({
    key: 0,
    label: 'All',
  })
  const [clientCount, setClientCount] = useState({
    totalClients: 0,
    trialClient: 0,
    licensedClient: 0,
  })
  const [edit, setEdit] = useState()
  const [endDate, setEndDate] = useState(null)
  const [deleteUserDailog, setDeleteUserDialog] = useState(false)
  const [sendEmailDialog, setSendEmailDialog] = useState(null)
  const [selectedUser, setSelectedUser] = useState()
  const [clicked, setClicked] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [migrateUserDailog, setMigrateUserDialog] = useState(false)
  const [featureObject,setFeatureObject] = useState({})
  const [featuresList,setFeatureList] = useState([])

  const dateIsGreater = (original, compared) => {
    const origDate = moment(moment(original).format(formatDate))
    const comparedDate = moment(moment(compared).format(formatDate))
    return origDate.isAfter(comparedDate)
  }

  const checkDateError = () => {
    const endDateCheck = dateIsGreater(
      endDate,
      selectedUser.org_expiration_date,
    )
    const nullCheck = endDate === null
    const err = endDateCheck || nullCheck
    setDateError(err)
    return err
  }

  const handleDate = (newValue) => {
    setEndDate(newValue)
    setDateError(dateIsGreater(newValue, selectedUser.org_expiration_date))
  }

  const handleSaveEndDate = (id) => {
    if (!checkDateError()) {
      updateEndDate(id)
      setClicked(false)
    }
  }

  const handleCancelEndDate = () => {
    setEdit(null)
    setClicked(false)
    setDateError(false)
  }

  const sortTableData = (array, sortBy) => {
    return array?.sort((a, b) => {
      if (a[sortBy].toLocaleLowerCase() < b[sortBy].toLocaleLowerCase())
        return -1
      if (a[sortBy].toLocaleLowerCase() > b[sortBy].toLocaleLowerCase())
        return 1
      return 0
    })
  }

  sortTableData(userList, 'org_name')

  useEffect(() => {
    const totalClients = userList?.length
    const trialClient = userList?.filter((client) => {
      return client.org_type === 'TRIAL'
    })?.length
    const licensedClient = userList?.filter((client) => {
      return client.org_type === 'LICENSED'
    })?.length
    const noOfClients = {
      totalClients: totalClients,
      trialClient: trialClient,
      licensedClient: licensedClient,
    }
    setClientCount(noOfClients)
  }, [userList])

  const adminPanelHeadCells = [
    {
      id: 'org_name',
      numeric: false,
      disablePadding: true,
      label: 'Client Name',
      func: (data) => (
        <TableCell>
          <Circle
            sx={{
              width: '15px',
              marginRight: '10px',
              color: ({ palette: { colors } }) =>
                colors[
                  (data.is_active
                    ? 'EMERGING'
                    : data.org_status === 'DRAFT'
                    ? 'DORMANT'
                    : 'DECLINING'
                  ).toLowerCase()
                ],
              verticalAlign: 'middle',
            }}
          />
          {data.org_name}
        </TableCell>
      ),
    },
    {
      id: 'org_type',
      numeric: false,
      disablePadding: false,
      label: 'Client Type',
      func: (data) => (
        <TableCell>
          {data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()}
        </TableCell>
      ),
    },
    {
      id: 'user_created_date',
      numeric: false,
      disablePadding: false,
      label: 'User Created On',
      func: (data) => (
        <TableCell>{moment(data).format('DD-MMM-YYYY')}</TableCell>
      ),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      func: (data) => (
        <Tooltip title={data}>
          <TableCell
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data}{' '}
          </TableCell>
        </Tooltip>
      ),
    },
    {
      id: 'user_activation_date',
      numeric: false,
      disablePadding: false,
      label: 'Client Start Date',
      func: (data) => (
        <TableCell>{moment(data).format('DD-MMM-YYYY')}</TableCell>
      ),
    },
    {
      id: 'user_expiration_date',
      numeric: false,
      disablePadding: false,
      label: 'End Date',
      func: (data) => (
        <TableCell>
          {edit === data.id ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DatePicker
                  maxDate={new Date(selectedUser.org_expiration_date)}
                  selected={endDate}
                  value={endDate}
                  components={{ OpenPickerIcon: CalendarTodayRoundedIcon }}
                  onChange={handleDate}
                  renderInput={(params) => (
                    <DatePickerTextField {...params} size="large" />
                  )}
                />
              </Box>
              <Grid sx={{ marginTop: '10px' }}>
                <Typography variant="body3" sx={{ color: 'red' }}>
                  {dateError && endDate == null
                    ? 'Please select end date'
                    : dateError &&
                      dateIsGreater(endDate, selectedUser.org_expiration_date)
                    ? 'End Date should not be greater than client end date'
                    : ''}
                </Typography>
              </Grid>
            </>
          ) : !moment(data.user_expiration_date).isValid() ? (
            '---'
          ) : (
            moment(data.user_expiration_date).format('DD-MMM-YYYY')
          )}
        </TableCell>
      ),
    },
    {
      id: 'modify',
      numeric: false,
      disablePadding: false,
      label: 'Modify',
      func: (data) => (
        <TableCell>
          {data && (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {(clicked === true && edit === data.id) ||
              (dateError && edit === data.id) ? (
                <Grid sx={{ marginTop: '10px' }}>
                  <CheckCircleIcon
                    sx={{ color: (theme) => theme.palette.success.main }}
                    onClick={() => {
                      handleSaveEndDate(data.id)
                    }}
                  />

                  <CancelIcon
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      marginLeft: '5px',
                    }}
                    onClick={handleCancelEndDate}
                  />
                </Grid>
              ) : (
                <BorderColorIcon
                  onClick={() => {
                    handleEdit(data.id, data)
                  }}
                  sx={{
                    color: ({ palette }) => palette.icon,
                  }}
                />
              )}
            </Grid>
          )}
        </TableCell>
      ),
    },
    {
      id: 'email_sent',
      numeric: false,
      disablePadding: false,
      label: 'Send Welcome Email',
      func: (data) => (
        <TableCell>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: data.email_sent === false ? '50px' : '24px',
            }}
          >
            {' '}
            <Grid sx={{ display: 'inline' }}>
              {' '}
              {data.email_sent === true ? 'Sent' : '-'}
            </Grid>
            <Grid sx={{ display: 'inline' }}>
              <Tooltip
                title={
                  data.email_sent === true
                    ? moment(data.email_sent_at).format('DD-MMM-YYYY HH:mm A')
                    : 'Not Sent'
                }
              >
                <Grid
                  sx={{
                    display: 'inline',
                    alignItems: 'center',
                    paddingBottom: '0px !important',
                  }}
                >
                  <InformationIcon />
                </Grid>
              </Tooltip>{' '}
              <SendIcon
                onClick={() => {
                  setSendEmailDialog(data)
                }}
                sx={{
                  color: ({ palette }) => palette.icon,
                  alignItems: 'end',
                  marginLeft: '30px',
                  textAlign: 'center',
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      ),
    },
    {
      id: 'migrate_to_new_ui',
      numeric: false,
      disablePadding: false,
      label: 'Migrate',
      func: (data) => (
        <TableCell>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              width:'20px'
            }}
          >
            {' '}
            {data && (
              <Button
              variant="contained"
              onClick={() => {
                handleMigrateButtonClick(data)
              }}
            >
              Migrate
            </Button>
            )}
          </Grid>
        </TableCell>
      ),
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: 'Delete',
      func: (data) => (
        <TableCell>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {' '}
            {data && (
              <DeleteOutline
                sx={{
                  color: ({ palette }) => palette.icon,
                }}
                onClick={() => {
                  setSelectedUser(data)
                  setDeleteUserDialog(true)
                  getData()
                }}
              />
            )}
          </Grid>
        </TableCell>
      ),
    },
  ]

  const GenericTableCell = ({ rowData, tableHeadCells }) => {
    const res = tableHeadCells.map(({ id, func }) => {
      if (
        id === 'delete' ||
        id === 'modify' ||
        id === 'user_expiration_date' ||
        id === 'org_name' ||
        id === 'email_sent' ||
        id === 'migrate_to_new_ui'
      ) {
        return func ? func(rowData) : rowData
      } else if (rowData[id] != null) {
        return func ? func(rowData[id]) : rowData[id]
      }
    })
    return res
  }

  const clientTypeChanged = (newVal) => {
    setClientTypeFilter(newVal)
  }

  const filteredData = clientTypeFilter
    ? clientTypeFilter.label === 'All'
      ? userList
      : userList.filter((client) => {
          return (
            client.org_type.toLocaleLowerCase() ===
            clientTypeFilter.label.toLocaleLowerCase()
          )
        })
    : userList

  const searchedData = filteredData?.filter((client) => {
    return client.username.toLowerCase().includes(searchValue.toLowerCase())
  })

  const postData = async (data) => {
    const request = NetworkUtil.userManagementSendingEmail()
    const userDetails = {
      email: data.email,
      user_id: data.user_id,
      id: data.id,
      org_id: data.org_id,
    }
    setShowLoader(true)
    await axios
      .post(request, userDetails)
      .then((res) => {
        setShowLoader(false)
        getData()
      })
      .catch((error) => {
        setShowLoader(false)
      })
  }

  const handleMigrateButtonClick = async (data) => {
    setShowLoader(true)
    const token = await getNewUiToken();
    if (!token) {
      console.error('Failed to retrieve token');
      return;
    }
    const request = `${BASE_URL}migration/user/features`;
    const options = getRequestOptions(token,true)
  
    try {
      const response = await axios.post(request, {"org_name":data.org_name,"user_email":data.email},options);
      if (response.status !== 200) {
        console.error('Error while fetching org features:', response);
        return;
      }
      const features = convertListKeysToCamelCase(response.data)
      setFeatureList(features)
      setFeatureObject(features.reduce((acc, feature) => ({ ...acc, [feature.key]: feature.isEnabled }), {}))
      setSelectedUser(data);
      setMigrateUserDialog(true);
    } catch (error) {
      console.error('Error while fetching org features:', error);
    }
    finally{
      setShowLoader(false)
    }
  };
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFeatureObject((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  function filterFalseValues(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value === false)
    );
  }
  const migrateUserToNewUI = async (data) => {
    setShowLoader(true)
    const token = await getNewUiToken()
    const featuresToDisable = filterFalseValues(featureObject)
    const request = {
      user_email: data.email,
      features_to_disable:featuresToDisable
    }
    const options = getRequestOptions(token,true)
    const userMigrationURL = `${BASE_URL}migration/user`
    axios.post(userMigrationURL, request, options)
  .then((res) => {
    console.log("Request succeeded");
  })
  .catch((error) => {
    console.error(`Request failed: with error ${error}`);
    setShowLoader(false)

    
  });
  setShowLoader(false)
}

  const handleDownloadUser = async () => {
    const request = NetworkUtil.userManagementDownloadAllUsers()
    setShowLoader(true)
    const excelFileName = 'User List'
    const filteredOrganizationData = searchedData.map(
      ({
        org_name: orgName,
        org_type: orgType,
        user_created_date: createdDate,
        email,
        user_activation_date: activationDate,
        user_expiration_date: expDate,
        email_sent: emailSent,
      }) => ({
        'Client Name': orgName,
        'Client Type': _.startCase(orgType.toLowerCase()),
        'User Created On': moment(createdDate).format('DD-MM-YYYY'),
        Email: email,
        'Client Start Date': moment(activationDate).format('DD-MM-YYYY'),
        'End Date': moment(expDate).format('DD-MM-YYYY'),
        'Send Welcome Mail': emailSent ? 'YES' : 'NO',
      }),
    )
    await axios
      .post(
        request,
        { organization_users: filteredOrganizationData },
        { responseType: 'blob' },
      )
      .then(({ data }) => {
        setShowLoader(false)
        saveAs(data, excelFileName) //eslint-disable-line
      })
  }

  const getData = () => {
    const request = NetworkUtil.userManagement()
    setShowLoader(true)
    axios
      .get(request)
      .then((res) => {
        setShowLoader(false)
        const userData = res.data.organization_users
        const data = userData.map((user) => {
          return { ...user, modify: true, delete: true }
        })
        setUserList(data)
      })
      .catch(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const deleteUser = (id) => {
    const data = {
      id: id,
    }
    const request = NetworkUtil.userManagementDeleteOp(id)
    axios
      .delete(request, data)
      .then(() => {
        getData()
      })
      .catch()
    setDeleteUserDialog(false)
  }

  const updateEndDate = (id) => {
    const data = {
      id: id,
      user_expiration_date: endDate,
    }
    const request = NetworkUtil.userManagementDeleteOp(id)

    axios
      .put(request, data)
      .then((res) => {
        getData()
        setEndDate()
        setEdit(null)
      })
      .catch()
  }

  const handleEdit = (id, data) => {
    setEndDate(data.user_expiration_date)
    setSelectedUser(data)
    setEdit(id)
    setClicked(true)
  }

  const handleSendingEmail = () => {
    postData(sendEmailDialog)
    setSendEmailDialog(null)
  }
  return (
    <>
      {showLoader && <OverlayLoader />}
      {sendEmailDialog && (
        <DiscardBucketPopUp
          text={'Are you sure you want to send the welcome email?'}
          handleDiscard={handleSendingEmail}
          title={'Send Welcome Email'}
          handleDiscardBucketCancel={() => {
            setSendEmailDialog(null)
          }}
          additionalMessage={`Note: This will reset the password for the user`}
          noImage
          cancelText={'No'}
          discardText={'Yes'}
        />
      )}
      {
        <Dialog
          onClose={() => setDeleteUserDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={deleteUserDailog}
          sx={{ minWidth: '600px' }}
        >
          <DialogContent
            dividers
            sx={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'spaceBetween',
              }}
            >
              <Typography pr={2} sx={{ width: '350px' }}>
                User {selectedUser?.username} data will be deleted
              </Typography>
              <ErrorIcon />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setDeleteUserDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                border: ({ palette }) => `1px solid ${palette.primary.main}`,
                color: ({ palette }) => palette.text.primary,
              }}
              variant="outlined"
              onClick={() => {
                setDeleteUserDialog(false)
                deleteUser(selectedUser.id)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      }

     <Dialog
        onClose={() => setMigrateUserDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={migrateUserDailog}
        sx={{ minWidth: '600px' }}
      >
        <DialogContent
          dividers
          sx={{ minHeight: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              mb: 2
            }}
          >
            <Typography pr={2} >
              User {selectedUser?.username} will be migrated to new UI
            </Typography>
            <ErrorIcon />
          </Box>
          <Typography pr={2} sx={{ alignSelf: 'flex-start', mb: 1 }}>
            Select features to enable:
          </Typography>
          <Grid container spacing={2}>
            {featuresList.map((feature) => (
              <Grid item xs={6} key={feature.key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={featureObject[feature.key]}
                      onChange={handleCheckboxChange}
                      name={feature.key}
                    />
                  }
                  label={feature.displayName}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              border: ({ palette }) => `1px solid ${palette.primary.main}`,
              color: ({ palette }) => palette.text.primary,
            }}
            variant="outlined"
            onClick={() => {
              setMigrateUserDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              border: ({ palette }) => `1px solid ${palette.primary.main}`,
              color: ({ palette }) => palette.text.primary,
            }}
            variant="outlined"
            onClick={() => {
              setMigrateUserDialog(false);
              migrateUserToNewUI(selectedUser);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <UserManagementTableUI
        searchValue={searchValue}
        clientTypeFilter={clientTypeFilter}
        adminPanelHeadCells={adminPanelHeadCells}
        setSearchValue={setSearchValue}
        GenericTableCell={GenericTableCell}
        searchedData={searchedData}
        clientTypeChanged={clientTypeChanged}
        clientCount={clientCount}
        handleAddUser={handleAddUser}
        showLoader={showLoader}
        handleDownloadUser={handleDownloadUser}
      />
    </>
  )
}
export default UserManagementTable
